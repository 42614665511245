import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://0c9d2cb37a09f6f216fead4dcdc8d6c6@o4507403934957568.ingest.us.sentry.io/4507403937513472",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()